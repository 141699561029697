import {
  collection,
  limit,
  orderBy,
  query,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "@firebase/firestore";
import { storeToRefs } from "pinia";
import { SubmittedDocument } from "types/SubmittedDocument";
import { DocumentState } from "~/types/enums/DocumentState";
import { Revision } from "~/types/Revision";

import { DocumentViewMode } from "types/enums/DocumentViewMode.enum";
import { Student } from "~/classes/models/students/student.model";
import { StudentsService } from "~/classes/models/students/students.service";

export const useSubmittedDocument = (documentId: string) =>
  definePiniaStore(`/submitted/documents/${documentId}`, () => {
    const documentId = ref<string>("");
    const revision = ref<Revision>();
    const student = ref<Student | undefined>();
    const submittedDocument = ref<SubmittedDocument>();

    const initialize = async (docId: string, mode: DocumentViewMode) => {
      documentId.value = docId;
      const db = useFirestore();

      const viewMode = mode;
      const documentViewModeStore = useDocumentViewMode(docId)();
      documentViewModeStore.setDocumentViewMode(viewMode);

      const submittedDocumentRef = doc(db, `/documents/${docId}`);
      const submittedDocumentDoc = await getDoc(submittedDocumentRef);
      submittedDocument.value = {
        ...submittedDocumentDoc.data(),
        id: submittedDocumentDoc.id,
      } as SubmittedDocument;

      if (submittedDocument.value.startGradingAtTimestamp == undefined) {
        submittedDocument.value.startGradingAtTimestamp = new Date().getTime();
        await updateDoc(submittedDocumentRef, {
          startGradingAtTimestamp:
            submittedDocument.value.startGradingAtTimestamp,
        });
      }

      if (submittedDocument.value.studentId) {
        try {
          student.value = await StudentsService.get(
            submittedDocument.value.studentId
          );
        } catch (error) {
          // empty
        }
      }

      const revisionsRef = collection(db, `/documents/${docId}/revisions`);
      const revisionsQuery = query(
        revisionsRef,
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1)
      );
      const response = await getDocs(revisionsQuery);
      if (response.docs.length == 0) {
        return null;
      }
      const revisionDoc = response.docs[0];
      revision.value = revisionDoc.data() as Revision;
      revision.value.id = revisionDoc.id;

      if (
        submittedDocument.value.state == DocumentState.submitted &&
        viewMode == DocumentViewMode.teacher
      ) {
        await updateDocumentState(DocumentState.grading);
      }
    };

    const saveRevision = async (revision: Revision) => {
      const { save, saved } = useDocumentSavingStatusStore(documentId.value);
      save();
      const db = useFirestore();
      const revisionRef = doc(
        db,
        `/documents/${documentId.value}/revisions/${revision.id}`
      );
      await updateDoc(revisionRef, revision);
      saved();
    };

    const saveDocument = async () => {
      if (!submittedDocument.value) return;

      const db = useFirestore();
      const submittedDocumentRef = doc(db, `/documents/${documentId.value}`);
      await updateDoc(submittedDocumentRef, submittedDocument.value);
    };

    const updateDocumentState = async (state: DocumentState) => {
      const documentChecklistStore = useDocumentChecklistStore(
        documentId.value
      );
      const { useTotalScore, useTotalEarnedScore } = storeToRefs(
        documentChecklistStore
      );

      const db = useFirestore();
      const submittedDocumentRef = doc(db, `/documents/${documentId.value}`);

      const docData: any = {
        state: state,
      };

      if (state == DocumentState.graded) {
        docData["earnedPoints"] = useTotalEarnedScore.value;
        docData["maxPoints"] = useTotalScore.value;
        docData["finishedGradingAtTimestamp"] = new Date().getTime();
      }

      if (submittedDocument.value) {
        submittedDocument.value.state = state;
      }

      await updateDoc(submittedDocumentRef, docData);
    };

    const documentLevel = computed(() => {
      return submittedDocument.value?.levelId;
    });

    const documentLesson = computed(() => {
      return submittedDocument.value?.lessonId;
    });

    const showGeneralCommentForm = ref<boolean>(false);

    const hasContacts = computed(() => {
      if (student.value == undefined) return false;

      return false;
    });

    return {
      documentId,
      submittedDocument,
      revision,
      documentLevel,
      documentLesson,
      showGeneralCommentForm,
      initialize,
      saveDocument,
      saveRevision,
      updateDocumentState,

      // Student info
      student,
      hasContacts,
    };
  });
